<script setup lang="ts">
import { onMounted, ref } from 'vue'
import DashboardStatsService from '@/services/dashboardStatsService'
import SpeedChart from '@/views/charts/sites/speedChart.vue'
import { useRootStore } from '@/stores/root'
import { useRouter } from 'vue-router'
import CircularProgress from '@/components/CircularProgress.vue'

interface PSIStatsData {
  mobile: {
    speed_index_above_70: number
    speed_index_below_70: number
    load_time_above_3s: number
    load_time_below_3s: number
  }
  desktop: {
    speed_index_above_80: number
    speed_index_below_80: number
    load_time_above_3s: number
    load_time_below_3s: number
  }
  total_sites: number
}

const loading = ref(false)
const data = ref<PSIStatsData>()
const rootStore = useRootStore()
const router = useRouter()
const getPsiStats = async () => {
  try {
    loading.value = true
    const res = await DashboardStatsService.fetchPSIStats()
    data.value = res
  } catch (error) {
    rootStore.showSnackbar('Could not fetch PSI stats', 'error')
  } finally {
    loading.value = false
  }
}

const plainNumberToPercentage = (number: number) => {
  return data.value?.total_sites ? (number / data.value.total_sites) * 100 : 0
}

//:to="{name: 'sites-psi', query: {strategy: 'desktop', is_above: 'true', metric: 'page_speed_score'}}"
const gotoPSISitesPage = (
  strategy: 'mobile' | 'desktop',
  metric: 'page_speed_score' | 'load_time',
  is_above: boolean,
) => {
  router.push({
    name: 'sites-psi',
    query: {
      strategy,
      metric,
      is_above: is_above ? 'yes' : 'no',
    },
  })
}

onMounted(() => {
  getPsiStats()
})
</script>
<template>
  <VCard :loading="loading" border="0">
    <VCardTitle class="text-lg pl-0 font-weight-semibold"
      >Site PageSpeed Insights</VCardTitle
    >
    <VRow>
      <VCol cols="6" md="6" sm="12" xs="12">
        <VCard
          style="background-color: rgb(var(--v-theme-ds-spi-dm))"
          border="0"
        >
          <VCol class="pt-0">
            <VCardText>
              <h6 class="text-lg float-left text-no-wrap font-weight-semibold">
                Desktop
              </h6>
            </VCardText>
          </VCol>
          <VCardText class="pt-3">
            <VRow>
              <VCol
                cols="3"
                md="3"
                class="d-flex justify-center align-center flex-column"
              >
                <VCard
                  border="0"
                  class="card-custom"
                  align="center"
                  :style="`cursor: ${
                    data?.desktop.speed_index_above_80 !== 0
                      ? 'pointer'
                      : 'not-allowed'
                  }`"
                  @click="
                    data?.desktop.speed_index_above_80 !== 0 &&
                      gotoPSISitesPage('desktop', 'page_speed_score', true)
                  "
                >
                  <CircularProgress
                    label="Sites"
                    size="180"
                    color="on-primary"
                    :label-value="data?.desktop.speed_index_above_80.toString()"
                    :value="
                      plainNumberToPercentage(
                        data?.desktop.speed_index_above_80,
                      )
                    "
                    max-value="100"
                    :is-colored-label="true"
                  />
                  <span class="text-sm-caption mt-1">
                    Above 85 page speed
                  </span>
                </VCard>
              </VCol>
              <VCol
                cols="3"
                md="3"
                class="d-flex justify-center align-center flex-column"
              >
                <VCard
                  class="card-custom"
                  align="center"
                  :style="`cursor: ${
                    data?.desktop.speed_index_below_80 !== 0
                      ? 'pointer'
                      : 'not-allowed'
                  }`"
                  border="0"
                  @click="
                    data?.desktop.speed_index_below_80 !== 0 &&
                      gotoPSISitesPage('desktop', 'page_speed_score', false)
                  "
                >
                  <CircularProgress
                    label="Sites"
                    color="error"
                    size="180"
                    :label-value="data?.desktop.speed_index_below_80.toString()"
                    :value="
                      plainNumberToPercentage(
                        data?.desktop.speed_index_below_80,
                      )
                    "
                    max-value="100"
                    :is-colored-label="true"
                  />
                  <span class="text-sm-caption mt-1">
                    Below 85 page speed
                  </span>
                </VCard>
              </VCol>
              <VCol
                cols="3"
                md="3"
                class="d-flex justify-center align-center flex-column"
              >
                <VCard
                  class="card-custom"
                  align="center"
                  :style="`cursor: ${
                    data?.desktop.load_time_below_3s !== 0
                      ? 'pointer'
                      : 'not-allowed'
                  }`"
                  border="0"
                  @click="
                    data?.desktop.load_time_below_3s !== 0 &&
                      gotoPSISitesPage('desktop', 'load_time', false)
                  "
                >
                  <CircularProgress
                    label="Sites"
                    color="on-primary"
                    size="180"
                    :label-value="data?.desktop.load_time_below_3s.toString()"
                    :value="
                      plainNumberToPercentage(data?.desktop.load_time_below_3s)
                    "
                    max-value="100"
                    :is-colored-label="true"
                  />
                  <!--                  <speed-chart-->
                  <!--                    label="Sites"-->
                  <!--                    color="success"-->
                  <!--                    :label-value="data?.desktop.load_time_below_3s"-->
                  <!--                    :value="-->
                  <!--                      plainNumberToPercentage(data?.desktop.load_time_below_3s)-->
                  <!--                    "-->
                  <!--                  />-->
                  <span class="text-sm-caption mt-1">
                    Below 3sec load time
                  </span>
                </VCard>
              </VCol>
              <VCol
                cols="3"
                md="3"
                class="d-flex justify-center align-center flex-column"
              >
                <VCard
                  class="card-custom"
                  align="center"
                  :style="`cursor: ${
                    data?.desktop.load_time_above_3s !== 0
                      ? 'pointer'
                      : 'not-allowed'
                  }`"
                  border="0"
                  @click="
                    data?.desktop.load_time_above_3s !== 0 &&
                      gotoPSISitesPage('desktop', 'load_time', true)
                  "
                >
                  <CircularProgress
                    label="Sites"
                    color="error"
                    size="180"
                    :label-value="data?.desktop.load_time_above_3s.toString()"
                    :value="
                      plainNumberToPercentage(data?.desktop.load_time_above_3s)
                    "
                    max-value="100"
                    :is-colored-label="true"
                  />
                  <span class="text-sm-caption mt-1">
                    Above 3sec load time
                  </span>
                </VCard>
              </VCol>
            </VRow>
          </VCardText>
        </VCard>
      </VCol>
      <VCol cols="6" md="6" sm="12" xs="12">
        <VCard
          style="background-color: rgb(var(--v-theme-ds-spi-dm))"
          border="0"
        >
          <VCol class="py-0">
            <VCardText>
              <h6 class="text-lg float-left text-no-wrap font-weight-semibold">
                Mobile
              </h6>
            </VCardText>
          </VCol>
          <VCardText>
            <VRow>
              <VCol
                cols="3"
                md="3"
                class="d-flex justify-center align-center flex-column"
              >
                <VCard
                  class="card-custom"
                  align="center"
                  :style="`cursor: ${
                    data?.mobile.speed_index_above_70 !== 0
                      ? 'pointer'
                      : 'not-allowed'
                  }`"
                  border="0"
                  @click="
                    data?.mobile.speed_index_above_70 !== 0 &&
                      gotoPSISitesPage('mobile', 'page_speed_score', true)
                  "
                >
                  <!--                  <speed-chart-->
                  <!--                    label="Sites"-->
                  <!--                    :label-value="data?.mobile?.speed_index_above_70"-->
                  <!--                    color="success"-->
                  <!--                    :value="-->
                  <!--                      plainNumberToPercentage(-->
                  <!--                        data?.mobile?.speed_index_above_70,-->
                  <!--                      )-->
                  <!--                    "-->
                  <!--                  />-->
                  <CircularProgress
                    label="Sites"
                    color="on-primary"
                    size="180"
                    :label-value="data?.mobile.speed_index_above_70.toString()"
                    :value="
                      plainNumberToPercentage(data?.mobile.speed_index_above_70)
                    "
                    max-value="100"
                    :is-colored-label="true"
                  />
                  <span class="text-sm-caption mt-1">
                    Above 80 page speed
                  </span>
                </VCard>
              </VCol>
              <VCol
                cols="3"
                md="3"
                class="d-flex justify-center align-center flex-column"
              >
                <VCard
                  class="card-custom"
                  align="center"
                  :style="`cursor: ${
                    data?.mobile.speed_index_below_70 !== 0
                      ? 'pointer'
                      : 'not-allowed'
                  }`"
                  border="0"
                  @click="
                    data?.mobile.speed_index_below_70 !== 0 &&
                      gotoPSISitesPage('mobile', 'page_speed_score', false)
                  "
                >
                  <CircularProgress
                    label="Sites"
                    color="error"
                    size="180"
                    :label-value="data?.mobile.speed_index_below_70.toString()"
                    :value="
                      plainNumberToPercentage(data?.mobile.speed_index_below_70)
                    "
                    max-value="100"
                    :is-colored-label="true"
                  />
                  <span class="text-sm-caption mt-1">
                    Below 80 page speed
                  </span>
                </VCard>
              </VCol>
              <VCol
                cols="3"
                md="3"
                class="d-flex justify-center align-center flex-column"
              >
                <VCard
                  class="card-custom"
                  align="center"
                  :style="`cursor: ${
                    data?.mobile.load_time_below_3s !== 0
                      ? 'pointer'
                      : 'not-allowed'
                  }`"
                  border="0"
                  @click="
                    data?.mobile.load_time_below_3s !== 0 &&
                      gotoPSISitesPage('mobile', 'load_time', false)
                  "
                >
                  <CircularProgress
                    label="Sites"
                    color="on-primary"
                    size="180"
                    :label-value="data?.mobile.load_time_below_3s.toString()"
                    :value="
                      plainNumberToPercentage(data?.mobile.load_time_below_3s)
                    "
                    max-value="100"
                    :is-colored-label="true"
                  />
                  <span class="text-sm-caption mt-1">
                    Below 3.5sec load time
                  </span>
                </VCard>
              </VCol>
              <VCol
                cols="3"
                md="3"
                class="d-flex justify-center align-center flex-column"
              >
                <VCard
                  class="card-custom"
                  align="center"
                  :style="`cursor: ${
                    data?.mobile.load_time_above_3s !== 0
                      ? 'pointer'
                      : 'not-allowed'
                  }`"
                  border="0"
                  @click="
                    data?.mobile.load_time_above_3s !== 0 &&
                      gotoPSISitesPage('mobile', 'load_time', true)
                  "
                >
                  <CircularProgress
                    label="Sites"
                    color="error"
                    size="180"
                    :label-value="data?.mobile.load_time_above_3s.toString()"
                    :value="
                      plainNumberToPercentage(data?.mobile.load_time_above_3s)
                    "
                    max-value="100"
                    :is-colored-label="true"
                  />
                  <span class="text-sm-caption mt-1">
                    Above 3.5sec load time
                  </span>
                </VCard>
              </VCol>
            </VRow>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </VCard>
</template>
<style scoped lang="scss">
.card-custom {
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: transparent;
  }
  //
  //  cursor: pointer;
  //  box-shadow: 0 0 0 0.15rem rgba(0, 37, 68, 0.6);
  //  // give it a little elevation effect
  //  transform: translateY(-0.25rem);
  //}
}
</style>
