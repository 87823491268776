<script setup>
import { useUserStore } from '@/stores/user'
import { getDashboardResourceStats } from '@/services/userService'
import { formatNumberAsWholeNumber } from '@/utils/numeralHelper.ts'
import Filters from '@/filters'
import { onMounted, ref } from 'vue'
import CircularProgress from '@/components/CircularProgress.vue'
const userStore = useUserStore()
const loading = ref(true)
import { useTheme } from 'vuetify'
import { computed } from 'vue'
import { hexToRgb } from '@layouts/utils'
const theme = useTheme()

const statistics = ref([
  {
    title: 'Visits',
    stats: '0/0',
    icon: 'v2-icons:profile-verified',
    name: 'visits',
    color: 'error',
    value: 0,
    maxValue: 0,
  },
  // {
  //   title: 'Bandwidth',
  //   stats: '0 GB / 0 GB',
  //   icon: 'tabler-users',
  //   color: 'info',
  // },
  {
    title: 'Disk',
    stats: '0GB/0GB',
    icon: 'v2-icons:disk-icon',
    color: 'warning',
    name: 'disk',
    value: 0,
    maxValue: 0,
  },
  {
    title: 'Next Billing In',
    stats: 'N/A',
    icon: 'v2-icons:credit-card',
    color: 'on-primary',
    name: 'billing',
    value: 0,
    maxValue: 0,
  },
])
const getBillingDays = async () => {
  const nextDate = userStore.userProfile?.nextRenewalDate
  if (nextDate) {
    const res = Filters.date.getDaysFromNowToSpecificDate(nextDate)
    const billingObj = statistics.value.find(
      (statsObj) => statsObj?.name.toLowerCase() === 'billing',
    )
    billingObj.stats = res
    if (res.split(' ')[1] === 'Days') {
      billingObj.value = Number(res.split(' ')[0])
      billingObj.maxValue = 30
    } else if (res.split(' ')[1] === 'Hours') {
      billingObj.value = Number(res.split(' ')[0])
      billingObj.maxValue = 24
    } else {
      billingObj.value = Number(res.split(' ')[0])
      billingObj.maxValue = 60
    }
  }
}
const getResourceStats = async () => {
  try {
    const data = await getDashboardResourceStats()
    if (data.siteStorageUsage) {
      const diskData = data.siteStorageUsage?.[0] ?? null
      if (diskData) {
        const diskObj = statistics.value.find(
          (statsObj) => statsObj.title.toLowerCase() === 'disk',
        )
        diskObj.value = diskData?.total_usage_recorded
          ? (diskData?.total_usage_recorded / 1024).toFixed(2)
          : 0
        diskObj.maxValue = diskData?.total_allowed_usage / 1024 ?? 0
        diskObj.stats = `${
          diskData?.total_usage_recorded
            ? (diskData?.total_usage_recorded / 1024).toFixed(2)
            : 0
        }GB/${
          diskData?.total_allowed_usage
            ? diskData?.total_allowed_usage / 1024
            : 0
        }GB`
      }
    }
    if (data.siteViewUsage) {
      const visitsData = data.siteViewUsage?.[0] ?? null
      if (visitsData) {
        const visitsObj = statistics.value.find(
          (statsObj) => statsObj.title.toLowerCase() === 'visits',
        )
        visitsObj.value = visitsData?.total_usage_recorded
          ? visitsData?.total_usage_recorded
          : 0
        visitsObj.maxValue = visitsData?.total_allowed_usage
        visitsObj.stats = `${
          visitsData?.total_usage_recorded
            ? formatNumberAsWholeNumber(visitsData?.total_usage_recorded)
            : 0
        } / ${
          visitsData?.total_allowed_usage
            ? formatNumberAsWholeNumber(visitsData?.total_allowed_usage)
            : 0
        }`
      }
    }
  } catch (e) {
    return
  }
}
onMounted(async () => {
  await getResourceStats()
  await getBillingDays()
  loading.value = false
})
// const Colors = computed(() => {
//   const currentTheme = theme.current.value.colors
//   // const variableTheme = theme.current.value.variables
//   return {
//     'ds-card-ru-bg-color': `rgba(${hexToRgb(
//       currentTheme['ds-card-res-usage'],
//     )})`,
//     // 'ds-card-ru-inner-color': `rgba(${hexToRgb(currentTheme['on-surface'])} ${
//     //   variableTheme['low-emphasis-opacity']
//     // })`,
//   }
// })
</script>

<template>
  <VCard
    border="0"
    style="background-color: rgb(var(--v-theme-ds-card-res-usage)) !important"
  >
    <VCardText class="pt-6">
      <VRow no-gutters class="d-flex align-center">
        <VCol cols="12" md="3" lg="3">
          <VCardTitle class="text-h5"> Resource Usage </VCardTitle>
          <VCardSubtitle class="text-md">
            Track your resource usage here.
          </VCardSubtitle>
        </VCol>
        <VCol
          v-for="item in statistics"
          :key="item.title"
          cols="12"
          md="3"
          lg="3"
          class="px-1"
        >
          <VCard variant="flat" class="py-5" border="0">
            <div class="d-flex align-center">
              <!--              <VAvatar-->
              <!--                :color="item.color"-->
              <!--                variant="tonal"-->
              <!--                size="42"-->
              <!--                class="me-3"-->
              <!--              >-->
              <!--                <VIcon size="24" :icon="item.icon" />-->
              <!--              </VAvatar>-->
              <CircularProgress
                :color="item.color"
                :icon="item.icon"
                :value="item.value"
                :max-value="item.maxValue"
              />
              <div class="d-flex flex-column pl-0">
                <VListItemTitle
                  v-if="item.stats !== 'N/A'"
                  class="d-flex flex-row font-weight-medium pl-0"
                >
                  <strong> {{ item.stats.split('/')[0] }}</strong>
                  <span v-if="item.stats.includes('/')">/</span>
                  {{ item.stats.split('/')[1] }}
                </VListItemTitle>
                <VListItemTitle
                  v-else
                  class="d-flex flex-row font-weight-bold pl-0"
                >
                  {{ item.stats }}
                </VListItemTitle>
                <VListItemSubtitle class="pl-0">
                  {{ item.title }}
                </VListItemSubtitle>
                <!--              <span class="title font-weight-regular">{{ item.stats }}</span>-->
                <!--              <span class="text-caption">-->
                <!--                {{ item.title }}-->
                <!--              </span>-->
              </div>
            </div>
          </VCard>
        </VCol>
      </VRow>
    </VCardText>
  </VCard>
</template>

<style lang="scss">
.custom-stats {
  padding: 20px 30px 20px 20px !important;
}
</style>
