import {isToday} from './common'

export const avatarText = (value: string) => {
  if (!value) return ''
  const names = value.split(' ')
  if (!names || names.length === 0) return ''
  const nameArray = [names[0], names.at(-1)]
  if (names.length > 2) nameArray.splice(1, 0, names[1])
  return nameArray.map((word) => word?.charAt(0).toUpperCase() ?? '').join('')
}

// TODO: Try to implement this: https://twitter.com/fireship_dev/status/1565424801216311297
export const kFormatter = (num: any) => {
  const regex = /\B(?=(\d{3})+(?!\d))/g

  return Math.abs(num) > 9999
    ? `${Math.sign(num) * +(Math.abs(num) / 1000).toFixed(1)}k`
    : Math.abs(num).toFixed(0).replace(regex, ',')
}

/**
 * Format and return date in Humanize format
 * Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 * Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Intl.DateTimeFormatOptions} formatting Intl object to format with
 */
export const formatDate = (
  value: any,
  formatting = { month: 'short', day: 'numeric', year: 'numeric' },
) => {
  if (!value) return value

  return new Intl.DateTimeFormat('en-US', formatting as any).format(
    new Date(value),
  )
}

/**
 * Return short human friendly month representation of date
 * Can also convert date to only time if date is of today (Better UX)
 * @param {String} value date to format
 * @param {Boolean} toTimeForCurrentDay Shall convert to time if day is today/current
 */
export const formatDateToMonthShort = (
  value: any,
  toTimeForCurrentDay = true,
) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }
  if (toTimeForCurrentDay && isToday(date)) {
    // @ts-ignore
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  // @ts-ignore
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}
