import { defineStore } from 'pinia'

interface PlansStoreState {
  plans?: {
    products: any
    addons: any
    wl_addons: any
  }
}

export const usePlansStore = defineStore('plans', {
  state: (): PlansStoreState => ({
    plans: undefined,
  }),
  getters: {
    productPlans(): any[] {
      let plans = this.plans?.products?.products ?? []
      plans = plans.map((plan: any) => ({
        ...plan,
        label: `${plan.name} ${
          plan.default_price?.unit_amount
            ? ` ($${(plan.default_price?.unit_amount / 100).toFixed(2)})`
            : ' ($0)'
        }`,
      }))
      return plans
    },
    offlineBillingProductPlans(): any[] {
      let plans = this.plans?.products?.products ?? []
      plans = plans.map((plan: any) => ({
        ...plan,
        label: `${plan.name}`,
      }))
      return plans
    },
    addOnPlans(): any[] {
      let addons = this.plans?.addons?.addons ?? []
      addons = addons.map((addon: any) => ({
        ...addon,
        label: `${addon.name} ${
          addon.default_price?.unit_amount
            ? ` ($${(addon.default_price?.unit_amount / 100).toFixed(2)})`
            : ''
        }`,
      }))
      return addons
    },
    offlineBillingAddOnPlans(): any[] {
      let addons = this.plans?.addons?.addons ?? []
      addons = addons.map((addon: any) => ({
        ...addon,
        label: `${addon.name}`,
      }))
      return addons
    },
    getPriceById: (state) => (planId: string) => {
      const getPrice = (
        list:
          | { id: string; default_price?: { unit_amount: number } }[]
          | undefined,
        id: string,
      ) => list?.find((item) => item.id === id)?.default_price?.unit_amount

      return (
        getPrice(state.plans?.products?.products, planId) ||
        getPrice(state.plans?.addons?.addons, planId)
      )

      // const plan = state.plans?.products?.products?.find(
      //   (p: any) => p.id === planId,
      // )
      // if (plan) return plan?.default_price?.unit_amount
      // const addon = state.plans?.addons?.addons?.find(
      //   (p: any) => p.id === planId,
      // )
      // return addon?.default_price?.unit_amount
    },
    getPlanTypeById: (state) => (planId: string) => {
      const findType = (
        list: { id: string; metadata?: { type: string } }[] | undefined,
        id: string,
      ) => list?.find((item) => item.id === id)?.metadata?.type

      return (
        findType(state.plans?.products?.products, planId) ||
        findType(state.plans?.addons?.addons, planId)
      )
    },
    getPlanNameByPriceId: (state) => (planId: string) => {
      const findType = (
        list: { id: string; name?: { type: string } }[] | undefined,
        id: string,
      ) => list?.find((item) => item.id === id)?.name

      return (
        findType(state.plans?.products?.products, planId) ||
        findType(state.plans?.addons?.addons, planId)
      )
    },
    getProductPlanNameFromPriceId: (state) => (priceId: string) => {
      const plan = state.plans?.products?.products?.find(
        (p: any) => p.default_price?.id === priceId,
      )
      return plan?.name
    },
    getProviderFromPlan: (state) => (priceId: string) => {
      const plan = state.plans?.products?.products?.find(
        (p: any) => p.default_price.id === priceId,
      )
      return plan?.metadata?.provider
    },
  },
})
