<script setup>
import VueApexCharts from 'vue3-apexcharts'
import { useTheme } from 'vuetify'
import { computed } from 'vue'
import { hexToRgb } from '@layouts/utils'

const props = defineProps({
  label: {
    type: String,
    default: '',
    required: false,
  },
  labelValue: {
    type: String,
    default: '',
    required: false,
  },
  icon: {
    type: String,
    default: '',
    required: false,
  },
  color: {
    type: String,
    default: 'primary',
    required: false,
  },
  size: {
    type: Number,
    default: 0,
    required: false,
  },
  value: {
    type: Number,
    default: 0,
    required: false,
  },
  maxValue: {
    type: Number,
    default: 100,
    required: false,
  },
  startAngle: {
    type: Number,
    default: 0,
    required: false,
  },
  endAngle: {
    type: Number,
    default: 360,
    required: false,
  },
  isColoredLabel: {
    type: Boolean,
    default: false,
    required: false,
  },
  labelValueFontSize: {
    type: String,
    default: '1.8rem',
    required: false,
  },
  labelFontSize: {
    type: String,
    default: '0.8rem',
    required: false,
  },
  labelValueFontWeight: {
    type: Number,
    default: 700,
    required: false,
  },
  labelFontWeight: {
    type: Number,
    default: 400,
    required: false,
  },
  labelValueUnit: {
    type: String,
    default: '',
    required: false,
  },
  isGradiant: {
    type: Boolean,
    default: false,
    required: false,
  },
})
const theme = useTheme()
const calculateSeries = (value, maxValue) => {
  if (maxValue === 0) return [0] // Avoid division by zero
  return [(value / maxValue) * 100] // Calculate the percentage of the value relative to the maxValue
}

const fillOptions = computed(() => {
  const currentTheme = theme.current.value.colors
  const labelSuccessColor = `rgba(${hexToRgb(currentTheme.success)},0.2)`

  const chartColors = {
    donut: {
      series1: currentTheme.success,
      series2: '#28c76fb3',
      series3: '#28c76f80',
      series4: labelSuccessColor,
    },
  }
  const fillColor = `rgba(${hexToRgb(currentTheme[`${props.color}`])})`
  if (props.isGradiant) {
    return {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'diagonal1',
        shadeIntensity: 0.5,
        gradientToColors: [
          chartColors.donut.series1,
          chartColors.donut.series2,
          chartColors.donut.series3,
          chartColors.donut.series4,
        ],
        opacityFrom: 0.7,
        opacityTo: 1,
        stops: [0, 60, 100],
        colorStops: [
          {
            offset: 0,
            color: fillColor,
            opacity: 0,
          },
          {
            offset: 10,
            color: fillColor,
            opacity: 0.1,
          },
          {
            offset: 50,
            color: fillColor,
            opacity: 0.5,
          },

          {
            offset: 70,
            color: fillColor,
            opacity: 0.7,
          },
          {
            offset: 100,
            color: fillColor,
            opacity: 1,
          },
        ],
      },
    }
  } else {
    return {
      type: 'solid',
      colors: fillColor,
    }
  }
})

const chartOptions = computed(() => {
  const currentTheme = theme.current.value.colors
  const trackColor = `rgba(${hexToRgb(currentTheme[`${props.color}`])},0.2)`
  const headingColor = `rgba(${hexToRgb(currentTheme[`${props.color}`])})`
  const subHeadingColor = `rgba(${hexToRgb(currentTheme['on-primary'])})`
  return {
    plotOptions: {
      radialBar: {
        hollow: {
          size: props.label ? '60%' : '50%',
          image: `${props.icon}`,
          imageWidth: 28,
          imageHeight: 28,
          imageClipped: false,
          position: 'back',
          margin: 0,
        },
        track: {
          background: trackColor,
          strokeWidth: props.label ? '100%' : '30%',
        },
        dataLabels: {
          showOn: 'always',
          name: {
            show: props.label !== '',
            fontSize: props.labelFontSize,
            offsetY: 20,
            fontFamily: 'Manrope',
            color: [subHeadingColor],
            formatter: () => {
              return props.label
            },
          },
          value: {
            show: props.labelValue !== '',
            fontSize: props.labelValueFontSize,
            fontFamily: 'Manrope',
            color: props.isColoredLabel ? headingColor : subHeadingColor,
            fontWeight: props.labelValueFontWeight,
            offsetY: -15,
            formatter: () => {
              return props.labelValue
                ? `${props.labelValue}${props.labelValueUnit}`
                : ''
            },
          },
        },
        max: props.maxValue,
        strokeWidth: '8px',
        startAngle: props.startAngle,
        endAngle: props.endAngle,
      },
    },
    fill: fillOptions.value,
    stroke: {
      lineCap: 'round',
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
  }
})
</script>

<template>
  <!--  <div :style="{ maxHeight: `${props.size}px` }">-->
  <div class="mychart-1">
    <VueApexCharts
      id="apexchart-circular-progress"
      class="pa-0 ma-0"
      type="radialBar"
      :options="chartOptions"
      :series="calculateSeries(value, maxValue)"
      :width="size ? `${size - 30}px` : '150px'"
      :height="size ? `${size}px` : '150px'"
    />
    <VIcon v-if="icon !== ''" class="chart-icon" :icon="icon" size="28" />
  </div>
  <!--  </div>-->
</template>

<style lang="scss">
/* Add custom styles if needed */
#apexchart-circular-progress {
  * {
    font-family: 'Manrope', sans-serif !important;
  }
}
.mychart-1 {
  .chart-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.apexcharts-text,
.apexcharts-datalabel-value {
  fill: rgb(var(--v-theme-on-background)) !important;
}
</style>
