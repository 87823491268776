export enum WhiteLabelPortalEnums {
  PORTAL_NAME = 'portal-name',
  PORTAL_DOMAIN = 'portal-domain',
  PORTAL_LOGO = 'portal-logo',
  PORTAL_LOGO_HEIGHT = 'portal-logo-height',
  PORTAL_LOGO_WIDTH = 'portal-logo-width',
  PORTAL_SIDEBAR_LOGO = 'portal-sidebar-logo',
  PORTAL_MINI_LOGO = 'portal-mini-logo',
  PORTAL_FAVICON = 'portal-favicon',
  PORTAL_PRIMARY_COLOR = 'portal-primary-color',
  PORTAL_ON_PRIMARY_COLOR = 'portal-on-primary-color',
  PORTAL_SECONDARY_COLOR = 'portal-secondary-color',
  PORTAL_ON_SECONDARY_COLOR = 'portal-on-secondary-color',
  PORTAL_BACKGROUND_COLOR = 'portal-background-color',
  PORTAL_ON_BACKGROUND_COLOR = 'portal-on-background-color',
}
