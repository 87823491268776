import { defineStore } from 'pinia'

interface NotificationState {
  userNotification: object[]
  systemAlerts: object[]
  showSystemAlert: boolean
}

export const useNotificationStore = defineStore('notifications', {
  state: (): NotificationState => {
    return {
      userNotification: [],
      systemAlerts: [],
      showSystemAlert: true,
    }
  },
  getters: {
    getUserNotifications(): object[] {
      return this.userNotification
    },
    getSystemAlerts(): object[] {
      return this.systemAlerts
    },
  },
  actions: {
    setUserNotifications(notifications: object[]) {
      this.userNotification = notifications
    },
    setNewNotification(notification: object) {
      this.userNotification.unshift(notification)
    },
    setSystemAlerts(alerts: object[]) {
      this.systemAlerts = alerts
    },
    setNewSystemAlert(alert: object) {
      this.showSystemAlert = true
      this.systemAlerts.unshift(alert)
    },
  },
})
