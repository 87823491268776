const CurrencyFilters = {
  centsToUSD: (cents: number): string => {
    return `$ ${parseFloat((cents / 100).toString()).toFixed(2)} USD`
  },
  centsToUSDNumber: (cents: number): number => {
    return Number(parseFloat((cents / 100).toString()).toFixed(2))
  },
}

export default CurrencyFilters
