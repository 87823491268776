<script setup>
import { useTheme } from 'vuetify'
import { useThemeConfig } from '@core/composable/useThemeConfig'
import { hexToRgb } from '@layouts/utils'
import FullscreenLoader from '@/components/FullscreenLoader.vue'
import { useRootStore } from '@/stores/root'
import { computed, watchEffect } from 'vue'
import { usePlansStore } from '@/stores/plans'
import StripeService from '@/services/stripeService'
import { useUserStore } from '@/stores/user'
import FrontChat from '@/components/FrontChat.vue'
import { isBionicSite } from '@/utils/whiteLabelHelpers'
const {
  syncInitialLoaderTheme,
  syncVuetifyThemeWithTheme: syncConfigThemeWithVuetifyTheme,
  isAppRtl,
} = useThemeConfig()
const { global } = useTheme()

// ℹ️ Sync current theme with initial loader theme
syncInitialLoaderTheme()
syncConfigThemeWithVuetifyTheme()

const rootStore = useRootStore()
const userStore = useUserStore()
const snackbars = computed({
  get: () => rootStore.snackbars,
  set: (value) => {
    rootStore.snackbars = value
  },
})
const showFullscreenLoader = computed(() => rootStore.showFullscreenLoader)
const fullScreenLoadingText = computed(() => rootStore.fullScreenLoadingText)
const plansStore = usePlansStore()
const updateSnackbars = (snackbar) => {
  if (snackbars.value?.length === 1) snackbars.value = []
  else
    snackbars.value = snackbars.value?.splice(
      snackbars.value?.indexOf(snackbar),
      1,
    )
}
watchEffect(async () => {
  if (userStore.userProfile) {
    const plans = await StripeService.getPlans()
    plansStore.plans = plans
  }
})
</script>

<template>
  <VLocaleProvider :rtl="isAppRtl">
    <!-- ℹ️ This is required to set the background color of active nav link based on currently active global theme's primary -->
    <VApp
      :style="`--v-global-theme-primary: ${hexToRgb(
        global.current.value.colors.primary,
      )}`"
    >
      <div
        v-for="(snackbar, index) in snackbars"
        :key="snackbar.id"
        class="d-flex align-center"
        style="position: absolute; z-index: 999999"
      >
        <VSnackbar
          v-model="snackbar.show"
          transition="slide-x-transition"
          :timeout="snackbar.timeout"
          position="fixed"
          max-width="400"
          :style="'margin-top:' + 72 * index + 'px'"
          style="z-index: 999999"
          location="top right"
          variant="elevated"
          :color="snackbar.color"
          shaped
          multi-line
          close-on-back
          close-on-content-click
          @update:model-value="updateSnackbars"
        >
          <div class="d-flex flex-row align-center">
            <div class="mr-2">
              <VIcon size="25" :icon="snackbar.icon" />
            </div>
            <div>
              <span :id="snackbar.id" v-html="snackbar.text" />
            </div>
          </div>
          <template v-if="snackbar.opts?.action" #actions>
            <VBtn
              elevation="20"
              color="white"
              size="small"
              variant="flat"
              @click="
                snackbar.color === 'success'
                  ? snackbar.opts?.action?.onActionSuccess()
                  : snackbar.opts?.action?.onActionError()
              "
            >
              {{
                snackbar.color === 'success'
                  ? snackbar.opts?.action?.text.success
                  : snackbar.opts?.action?.text.error
              }}
            </VBtn>
          </template>
        </VSnackbar>
      </div>
      <FrontChat v-if="!userStore.isAdminUser" />
      <FullscreenLoader
        v-if="showFullscreenLoader"
        id="root-fullscreen-loader"
        :loading-text="fullScreenLoadingText"
      />
      <RouterView />
    </VApp>
  </VLocaleProvider>
</template>
