<script setup>
import WordPressService from '@/services/wpDetailsService'
import { isBionicSite } from '@/utils/whiteLabelHelpers'
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const loading = ref(false)
const lastMonthUpdatedData = ref([
  {
    title: 'Wordpress Core',
    stats: '0',
    icon: 'v2-icons:wordpress-icon',
    name: 'cores',
  },
  {
    title: 'Theme',
    stats: '0',
    icon: 'v2-icons:paint-board',
    name: 'themes',
  },
  {
    title: 'Plugins',
    stats: '0',
    icon: 'v2-icons:power-plug',
    name: 'plugins',
  },
])
const currentUpdatable = ref([
  {
    title: 'Wordpress Core',
    stats: 0,
    icon: 'v2-icons:wordpress-icon',
    name: 'cores',
  },
  {
    title: 'Theme',
    stats: 0,
    icon: 'v2-icons:paint-board',
    name: 'themes',
  },
  {
    title: 'Plugins',
    stats: 0,
    icon: 'v2-icons:power-plug',
    name: 'plugins',
  },
])

const totalUpdatable = computed(() => {
  let count = 0
  currentUpdatable.value.map((updatable) => {
    count += Number(updatable.stats)
  })
  return count
})
const totalUpdatedLastMonth = computed(() => {
  let count = 0
  lastMonthUpdatedData.value.map((updatable) => {
    count += Number(updatable.stats)
  })
  return count
})

const getUpdatableWPData = async () => {
  const data = await WordPressService.getDashboardUpdatableStats()
  currentUpdatable.value.map((item) => (item.stats = data[item.name]))
}
const getUpdatedWPData = async () => {
  const data = await WordPressService.getDashboardUpdatedStats()
  lastMonthUpdatedData.value.map((item) => (item.stats = data[item.name]))
}
const openDetailsPage = (type) => {
  router.push({ name: 'sites-wordpress-updates', query: { tab: type } })
}
onMounted(() => {
  loading.value = true
  const promise = getUpdatableWPData()
  const promise1 = getUpdatedWPData()
  Promise.all([promise, promise1]).then(() => {
    loading.value = false
  })
})
</script>
<template>
  <VCard :loading="loading" border="0">
    <VCardTitle class="text-lg pl-0 font-weight-semibold">
      Performance Management
    </VCardTitle>
    <VRow>
      <VCol cols="6">
        <VCard
          style="background-color: rgb(var(--v-theme-ds-pm-card-ulm))"
          :border="isBionicSite() ? 0 : 1"
          class="d-flex flex-column pa-0"
        >
          <VCardTitle class="float-left text-no-wrap font-weight-medium ma-3">
            Updates last month
            <span class="text-lg-h6 font-weight-bold">
              {{ totalUpdatedLastMonth }}
            </span>
          </VCardTitle>
          <VCardText>
            <VRow>
              <VCol
                v-for="item in lastMonthUpdatedData"
                :key="item.title"
                cols="6"
                sm="12"
                xs="12"
                lg="4"
                md="4"
              >
                <VCard
                  style="
                    background-color: rgb(var(--v-theme-grey-50));
                    height: 12rem;
                    max-width: 12rem;
                  "
                  border="0"
                  class="pa-4"
                >
                  <div class="d-flex flex-column h-100">
                    <!--                    <VAvatar variant="plain" size="42" class="me-3">-->
                    <VIcon size="32" :icon="item.icon" class="ma-3 h-50" />
                    <!--                    </VAvatar>-->

                    <div
                      class="d-flex flex-column justify-space-between ma-3 h-50"
                    >
                      <VListItemTitle class="font-weight-700 text-lg-h4">
                        {{ item.stats }}
                      </VListItemTitle>
                      <VListItemSubtitle class="mt-3">
                        {{ item.title }}
                      </VListItemSubtitle>
                      <!--              <span class="title font-weight-regular">{{ item.stats }}</span>-->
                      <!--              <span class="text-caption">-->
                      <!--                {{ item.title }}-->
                      <!--              </span>-->
                    </div>
                  </div>
                </VCard>
              </VCol>
            </VRow>
          </VCardText>
        </VCard>
      </VCol>
      <VCol cols="6">
        <VCard
          style="background-color: rgb(var(--v-theme-ds-pm-card-tuna))"
          :border="isBionicSite() ? 0 : 1"
          class="d-flex flex-column pa-0"
        >
          <VCardTitle class="float-left text-no-wrap font-weight-medium ma-3">
            Total updates needing attention
            <span class="text-lg-h6 font-weight-bold">
              {{ totalUpdatable }}
            </span>
          </VCardTitle>
          <VCardText class="">
            <VRow>
              <VCol
                v-for="(item, index) in currentUpdatable"
                :key="item.title + index"
                class="cursor-pointer wp-col"
                cols="6"
                sm="12"
                xs="12"
                lg="4"
                md="4"
                @click="openDetailsPage(item.name)"
              >
                <VCard
                  style="
                    background-color: rgb(var(--v-theme-grey-50));
                    height: 12rem;
                    max-width: 12rem;
                  "
                  border="0"
                  class="pa-4"
                >
                  <div class="d-flex flex-column h-100">
                    <!--                    <VAvatar variant="plain" size="42" class="me-3">-->
                    <VIcon size="32" :icon="item.icon" class="ma-3 h-50" />
                    <!--                    </VAvatar>-->

                    <div
                      class="d-flex flex-column justify-space-between ma-3 h-50"
                    >
                      <VListItemTitle class="font-weight-700 text-lg-h4">
                        {{ item.stats }}
                      </VListItemTitle>
                      <VListItemSubtitle class="mt-3">
                        {{ item.title }}
                      </VListItemSubtitle>
                    </div>
                  </div>
                </VCard>
              </VCol>
            </VRow>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </VCard>
</template>
<style scoped lang="scss">
.wp-col {
  transition: all 0.3s ease-in-out;

  &:hover {
    .v-card > div > .v-icon {
      transition: all 0.3s ease-in-out;
      transform: scale(1.2);
    }
  }
}
</style>
