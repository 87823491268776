import { User } from '@/models/user'

export const transformToUserProfileModel = (user: any): User => ({
  stripeSubscriptionStatus: user?.stripe_subscription_status,
  accountType: user?.accountType,
  createdAt: user?.created_at,
  updatedAt: user?.updated_at,
  fullName: user?.fullName,
  stripeCustomerId: user?.stripe_customer_id,
  stripeSubscriptionId: user?.stripe_subscription_id,
  stripeSubscriptionDate: user?.subscription_date,
  nextRenewalDate: user?.next_renewal_on,
  billingCycleDay: user?.billing_cycle_day,
  firstName: user?.firstName,
  lastName: user?.lastName,
  country: user?.country,
  describeAs: user?.describeAs,
  phoneNumber: user?.phoneNumber,
  avatar: user?.avatar,
  status: user?.status,
  email: user?.email,
  uuid: user?.uuid,
  id: user?.id,
  otp: user?.otp,
  avatarUrl: user?.avatarUrl,
  userAffiliateDetails: user?.userAffiliateDetails,
  activePayoutRequest: user?.activePayoutRequest,
  wlDetails: user?.wlDetails,
  wlPlatformOfCustomer: user?.wlPlatformOfCustomer,
})
