import { User as FireBaseUser } from 'firebase/auth'
import { auth } from '@/firebase'
import { User } from '@/models/user'
import { defineStore } from 'pinia'
import { unsubscribePusher } from '@/pusher/subscribe_unsubscribe_to_channel'
import { getUser } from '@/services/userService'
import { StripeSubscriptionStatusEnum } from '@/common/enums/stripeSubscriptionStatusEnum'
import { ProjectedBilling } from '@/models/projectedBilling'
import { WhiteLabelDetails } from '@/models/wlDetails'
import { StorageKeysEnum } from '@/common/enums/storageKeysEnum'

interface UserState {
  firebaseUser: FireBaseUser | null
  userProfile: User | null
  adminProfile: User | null
  visitedUID: string | null
  wlVisitingCustomer: boolean | null
  userOtp: object | undefined
  wlDetails?: WhiteLabelDetails | null
  wlPlatformOfCustomer?: WhiteLabelDetails | null
  userProjectedBilling: ProjectedBilling | object
}

export const useUserStore = defineStore('user', {
  state: (): UserState => {
    return {
      firebaseUser: null,
      userProfile: null,
      adminProfile: null,
      visitedUID: null,
      userOtp: {},
      userProjectedBilling: {},
      wlDetails: null,
      wlPlatformOfCustomer: null,
      wlVisitingCustomer: null,
    }
  },
  getters: {
    async accessToken(): Promise<string | undefined> {
      const token = await this.firebaseUser?.getIdToken()
      return token
    },
    userFirebaseUId(): string | undefined {
      const uid = this.firebaseUser?.uid ?? undefined
      return uid
    },
    userDbId(): number | undefined {
      const id = this.userProfile?.id
      return id
    },
    userHasUnpaidInvoice(): boolean {
      return (
        this.userProfile?.stripeSubscriptionStatus ===
        StripeSubscriptionStatusEnum.UNPAID
      )
    },
    getUserOtp(): any | undefined {
      return this.userOtp
    },
    getUserProjectedBilling(): ProjectedBilling {
      return <ProjectedBilling>this.userProjectedBilling
    },
    isAdminUser(): boolean {
      return (
        (!!this.adminProfile || !!this.visitedUID) &&
        this?.adminProfile?.accountType === 'admin'
      )
    },
    isCustomerUser(): boolean {
      return !this.adminProfile && !this.visitedUID
    },
    adminUserID(): number | undefined {
      return this.adminProfile?.id
    },
    isWLOwner(): boolean {
      return !!this.wlDetails
    },
    isWLOwnerAndLoggedInAsCustomer(): boolean {
      return (
        (this.adminProfile?.wlDetails &&
          this.adminProfile?.wlDetails?.id ===
            this.userProfile?.wlPlatformOfCustomer?.id) ||
        localStorage.getItem(StorageKeysEnum.WL_OWNER_VISITING_CUSTOMER) ===
          'true' ||
        !!this.wlVisitingCustomer
      )
    },
    isWLCustomer(): boolean {
      return !!this.wlPlatformOfCustomer && this.wlDetails === null
    },
  },
  actions: {
    setFirebaseUser(firebaseUser: FireBaseUser | null) {
      this.firebaseUser = firebaseUser
    },
    refreshFirebaseUser() {
      this.firebaseUser = auth.currentUser
    },
    setUserProfile(userProfile: User) {
      this.userProfile = userProfile
      this.userOtp =
        userProfile && userProfile.otp && userProfile.otp.length > 0
          ? userProfile.otp[0]
          : undefined
      this.wlDetails =
        userProfile && userProfile.wlDetails ? userProfile.wlDetails : null
      this.wlPlatformOfCustomer =
        userProfile && userProfile.wlPlatformOfCustomer
          ? userProfile.wlPlatformOfCustomer
          : null
    },
    setWlDetails(details: WhiteLabelDetails) {
      this.wlDetails = details
    },
    replaceAdminWithUser(userProfile: User) {
      if (
        userProfile?.accountType === 'user' &&
        (this.userProfile?.accountType === 'admin' ||
          !!this.userProfile?.wlDetails)
      ) {
        this.visitedUID = userProfile?.uuid ?? null
        this.adminProfile = this.userProfile
        this.userProfile = userProfile
      }
    },
    async backToAdmin() {
      if (this.adminProfile) {
        this.userProfile = this.adminProfile
        this.adminProfile = null
      }
      await this.updateUser()
      this.setUserProjectedBilling({})
      this.visitedUID = null
    },
    async updateUser() {
      return getUser().then((user) => {
        this.userProfile = user
        return user
      })
    },
    async logout() {
      this.userProfile?.id &&
        (await unsubscribePusher(this.userProfile!.id.toString()))
      await auth.signOut()
      localStorage.removeItem('customer-uuid')
      this.$reset()
    },
    setUserOtp(otp: any | undefined) {
      this.userOtp = otp
    },
    setUserProjectedBilling(projectedBilling: ProjectedBilling) {
      this.userProjectedBilling = projectedBilling
    },
    setWhiteLabelSupport(flag: boolean | undefined) {
      this.wlDetails = {
        ...this.wlDetails,
        is_wl_support_enabled: flag,
      } as WhiteLabelDetails
    },
  },
})
