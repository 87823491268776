export const ElementIDs = {
  AUTH: {
    Register: {
      EMAIL_INPUT: 'register-email-field',
      PASSWORD_INPUT: 'register-password-field',
      CONFIRM_PASSWORD_INPUT: 'register-confirm-password-field',
      REGISTER_BUTTON: 'register-submit-button',
      FIRST_NAME_INPUT: 'register-first-name-field',
      LAST_NAME_INPUT: 'register-last-name-field',
      SIGNUP_SOURCE_INPUT: 'register-signup-source-field',
      I_DESCRIBE_MYSELF_AS_BTN: 'register-i-describe-myself-as-btn',
      PRIVACY_POLICY_CHECKBOX: 'register-privacy-policy-checkbox',
      PRIVACY_POLICY_CHECKBOX_ERROR: 'register-privacy-policy-checkbox-error',
      SIGN_IN_INSTEAD_LINK: 'register-sign-in-instead-link',
      NO_OF_SITES_INPUT: 'register-no-of-sites-field',
    },
    LOGIN: {
      CREATE_AN_ACCOUNT_LINK: 'login-page-create-an-account-link',
      SUBMIT_BTN: 'login-page-submit-button',
      EMAIL_INPUT: 'login-page-email-field',
      PASSWORD_INPUT: 'login-page-password-field',
      REMEMBER_ME_CHECKBOX: 'login-page-remember-me-checkbox',
      FORGOT_PASSWORD_LINK: 'login-page-forgot-password-link',
    },
    AUTH_PROVIDER: {
      GOOGLE: 'google-auth-provider-btn',
    },
  },
  SIDEBAR_NAV: {
    _base: 'sidebar-nav',
    DASHBOARD: 'sidebar-nav-dashboard',
  },
  SITES: {
    _MY_SITE_BASE: 'my-site',
    _SHARED_SITE_BASE: 'shared-site',
    MY_SITES_TAB: 'my-sites-tab',
    SHARED_SITES_TAB: 'shared-sites-tab',
    NEW: {
      _base: 'new-site',
      PLAN_DROPDOWN: 'new-site-plan-dropdown',
      DOMAIN_INPUT: 'new-site-domain-input',
      ADDONS_DROPDOWN: 'new-site-addons-dropdown',
      CREATE_SITE_BUTTON: 'new-site-create-site-button',
      REGION_DROPDOWN: 'new-site-region-dropdown',
      SUBMIT_BUTTON: 'new-site-submit-button',
    },
    MIGRATION_REQUEST: {
      _base: 'migration-request',
      DOMAIN_INPUT: 'migration-request-domain-input',
      SUBMIT_BUTTON: 'migration-request-submit-button',
      WWW_DROPDOWN: 'migration-request-www-dropdown',
      WP_LOGIN_URL_INPUT: 'migration-request-login-url-input',
      WP_LOGIN_USERNAME_INPUT: 'migration-request-login-username-input',
      WP_LOGIN_PASSWORD_INPUT: 'migration-request-login-password-input',
      REGISTRAR_URL_INPUT: 'migration-request-registrar-url-input',
      REGISTRAR_USERNAME_INPUT: 'migration-request-registrar-username-input',
      REGISTRAR_PASSWORD_INPUT: 'migration-request-registrar-password-input',
      PLAN_DROPDOWN: 'migration-request-plan-dropdown',
      LOCATION_DROPDOWN: 'migration-request-location-dropdown',
      ADDONS_DROPDOWN: 'migration-request-addons-dropdown',
      NOTES_INPUT: 'migration-request-notes-input',
    },
    DETAILS: {
      _base: 'site-details',
      ONE_CLICK_LOGIN_BTN: 'wp-admin-btn',
      CHANGE_SITE_ENVIRONMENT_LIST_BTN:
        'site-details-change-site-environment-list-btn',
      CHANGE_SITE_ENVIRONMENT_LIST_ITEM: 'select-site-env-btn',
      GENERATE_OTP_BTN: 'generate-otp-btn',
    },
  },
  APP_HEADER_NAV: {
    USER_PROFILE_AVATAR: 'app-header-nav-user-profile-avatar',
    USER_PROFILE_LIST: {
      _base: 'app-header-nav-user-profile-list',
      PROFILE: 'app-header-nav-user-profile-list-profile-item',
      BILLINGS: 'app-header-nav-user-profile-list-billings-item',
      PAYMENT_METHODS: 'app-header-nav-user-profile-list-payment-methods-item',
      LOGOUT: 'app-header-nav-user-profile-list-logout-item',
    },
  },
}
