import axiosIns from '@axios'

export default class WpDetailsService {
  static async getWpCores(): Promise<any[]> {
    const {
      data: { data },
    } = await axiosIns.get('/wp-details/cores')
    return data
  }

  static async getWpPlugins(): Promise<any[]> {
    const {
      data: { data },
    } = await axiosIns.get('/wp-details/plugins')
    return data
  }

  static async getWpThemes(): Promise<any[]> {
    const {
      data: { data },
    } = await axiosIns.get('/wp-details/themes')
    return data
  }

  static async updateWpCores(coreVersion: string): Promise<any[]> {
    const {
      data: { data },
    } = await axiosIns.patch(`/wp-details/cores/${coreVersion}`)
    return data
  }

  static async updateWpThemes(themeName: string): Promise<any[]> {
    const {
      data: { data },
    } = await axiosIns.patch(`/wp-details/themes/${themeName}`)
    return data
  }

  static async updateWpPlugins(pluginName: string): Promise<any[]> {
    const {
      data: { data },
    } = await axiosIns.patch(`/wp-details/plugins/${pluginName}`)
    return data
  }

  static async getDashboardUpdatableStats() {
    const res = await axiosIns.get('/wp-details/updatable/stats')
    return res?.data?.data
  }

  static async getDashboardUpdatedStats() {
    const res = await axiosIns.get('/wp-details/updated/stats')
    return res?.data?.data
  }
}
