import axiosIns from '@axios'

export default class DashboardStatsService {
  static async fetchSitesStats() {
    const res = await axiosIns.get('/site/site-stats')
    if (res?.data?.data) {
      return res.data.data
    } else {
      throw new Error('Error fetching site stats')
    }
  }

  static async fetchPSIStats() {
    const res = await axiosIns.get('/site/dashboard/psi-stats')
    return res?.data?.data
  }
}
