<script setup>
import { useSkins } from '@core/composable/useSkins'
import { useThemeConfig } from '@core/composable/useThemeConfig'
import Loader from '@/components/Loader.vue'

import { defineAsyncComponent, onMounted } from 'vue'
import { useWindowSize } from '@vueuse/core'
import ErrorHeader from '@core/components/ErrorHeader.vue'
import { subscribeToPusher } from '@/pusher/subscribe_unsubscribe_to_channel'
import {
  getSystemAlerts,
  getUserNotification,
} from '@/services/notificationService'
import { useUserStore } from '@/stores/user'
import LogRocket from 'logrocket'

const DefaultLayoutWithVerticalNav = defineAsyncComponent({
  loader: () => import('./components/DefaultLayoutWithVerticalNav.vue'),
  loadingComponent: Loader,
  delay: 1000,
  errorComponent: ErrorHeader,
  timeout: 10000,
})
const store = useUserStore()
const { width: windowWidth } = useWindowSize()
const { switchToVerticalNavOnLtOverlayNavBreakpoint } = useThemeConfig()

// Remove below composable usage if you are not using horizontal nav layout in your app
switchToVerticalNavOnLtOverlayNavBreakpoint(windowWidth)

const { layoutAttrs, injectSkinClasses } = useSkins()

// setUser({
//   id: store.userProfile?.id,
//   email: store.userProfile?.email,
// })

if (import.meta.env.MODE !== 'development') {
  LogRocket.init('hedfor/bionicwp', {})
  LogRocket.identify(store.userProfile?.id?.toString(), {
    name: store.userProfile?.firstName,
    email: store.userProfile?.email,
    env: import.meta.env.MODE,
  })
}

injectSkinClasses()
onMounted(async () => {
  await subscribeToPusher(store.userProfile?.id)
  await getUserNotification()
  await getSystemAlerts()
})
</script>

<template>
  <DefaultLayoutWithVerticalNav v-bind="layoutAttrs" />
</template>

<style lang="scss">
// As we are using `layouts` plugin we need its styles to be imported
@use '@layouts/styles/default-layout';
</style>
