<script setup>
import { onMounted } from 'vue'

defineProps({
  loaderText: {
    type: String,
    default: 'Loading...',
  },
})
onMounted(() => {
  const loaderColor =
    localStorage.getItem('Materio-initial-loader-bg') || '#FFFFFF'
  const primaryColor =
    localStorage.getItem('Materio-initial-loader-color') || '#7367F0'

  if (loaderColor)
    document.documentElement.style.setProperty(
      '--initial-loader-bg',
      loaderColor,
    )
  if (primaryColor)
    document.documentElement.style.setProperty(
      '--initial-loader-color',
      primaryColor,
    )
})
</script>
<template>
  <div id="loader-component" class="loader-component">
    <div id="loading-bg">
      <div class="loader" />
    </div>
  </div>
</template>

<style scoped>
.loader-component {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
}

#loading-bg {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  block-size: 100%;
  inline-size: 100%;
}

.loader {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #002544;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}
</style>
