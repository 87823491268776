<script setup lang="ts">
import VueApexCharts from 'vue3-apexcharts'
import { useTheme } from 'vuetify'
import { hexToRgb } from '@layouts/utils'
import { computed, ref, watchEffect } from 'vue'
import DashboardStatsService from '@/services/dashboardStatsService'
import { useRootStore } from '@/stores/root'
import CircularProgress from '@/components/CircularProgress.vue'

const vuetifyTheme = useTheme()

interface StatsData {
  live?: number
  staging?: number
  total?: number
}

const siteStats = ref<StatsData | undefined>(undefined)
const loadingStats = ref(false)

const fetchSiteStats = async () => {
  loadingStats.value = true
  try {
    const statData: StatsData = await DashboardStatsService.fetchSitesStats()
    siteStats.value = statData
  } finally {
    loadingStats.value = false
  }
}
watchEffect(() => {
  fetchSiteStats()
})
</script>

<template>
  <VCard
    :title="
      siteStats && Object.values(siteStats).length && loadingStats
        ? ''
        : 'Website\'s Info'
    "
    width="auto"
    v-height="'250px'"
  >
    <!--    <VDivider />-->
    <template #append>
      <RouterLink
        v-if="
          siteStats &&
          Object.values(siteStats).length &&
          Object.values(siteStats).every((stat) => stat === 0)
        "
        class="link"
        to="/sites/add"
      >
        <VBtn class="" variant="outlined" rounded>
          Create one
          <VIcon size="20" class="arrow-icon ml-2">
            mdi-arrow-right
          </VIcon></VBtn
        >
      </RouterLink>
    </template>
    <VCardText
      v-if="
        siteStats &&
        Object.values(siteStats).length &&
        Object.values(siteStats).some((stat) => stat !== 0)
      "
      class="d-flex justify-space-between align-center pb-0"
    >
      <!--      <div class="d-flex flex-row align-center justify-space-around">-->
      <div class="w-100">
        <RouterLink to="sites" class="d-flex flex-row justify-space-around">
          <span>
            <VListItemTitle class="font-family-manrope pt-2">
              {{ siteStats?.live }}
            </VListItemTitle>
            <VListItemSubtitle class="mt-2"> Live </VListItemSubtitle>
          </span>
          <span>
            <VListItemTitle class="font-family-manrope pt-2">
              {{ siteStats?.staging }}
            </VListItemTitle>
            <VListItemSubtitle class="mt-2"> Staging </VListItemSubtitle>
          </span>
          <span>
            <VListItemTitle class="font-family-manrope pt-2">
              {{ siteStats?.total }}
            </VListItemTitle>
            <VListItemSubtitle class="mt-2"> Total </VListItemSubtitle>
          </span>
        </RouterLink>
      </div>
      <!--      </div>-->
      <div>
        <CircularProgress
          size="200"
          :value="Number(siteStats?.live)"
          color="warning"
          :text="siteStats?.total"
          :max-value="Number(siteStats?.live) + Number(siteStats?.staging)"
          :label-value="`${siteStats?.live + siteStats?.staging || 0}`"
          label="Total"
          :start-angle="-120"
          :end-angle="120"
          :is-colored-label="false"
          label-font-size="0.688rem"
          label-value-font-size="2.675rem"
          :label-value-font-weight="700"
          :label-font-weight="400"
        />
      </div>
    </VCardText>
    <VCardText
      v-else-if="
        siteStats &&
        Object.values(siteStats).length &&
        Object.values(siteStats).every((stat) => stat === 0)
      "
      class="mt-8"
    >
      <VRow>
        <VCol cols="8"> You have no sites yet. </VCol>
      </VRow>
    </VCardText>
    <VCardText
      v-else-if="loadingStats"
      class="w-auto d-flex flex-column align-center justify-center"
    >
      <VProgressCircular indeterminate color="primary" size="60" />
      <span class="mt-2"> Loading Stats </span>
    </VCardText>
    <VCardText
      v-else
      class="w-auto d-flex flex-column align-center justify-center"
    >
      <span class="mt-2"> Stats not available </span>
    </VCardText>
  </VCard>
</template>
<style lang="scss" scoped>
.link {
  transition: all 0.3s ease-in-out;

  .arrow-icon {
    margin: 0;
    transition: all 0.3s ease-in-out;
    padding: 0;
    margin-left: -3px;
  }

  &:hover {
    .arrow-icon {
      transform: translateX(4px);
    }
  }
}
</style>
