import { Plugin } from 'vue'
import { bgColor, textColor } from '@/directives/style/colors'
import { elemWidth } from '@/directives/style/width'
import { elemHeight } from '@/directives/style/height'

export const CustomDirectives: Plugin = {
  install(app) {
    app.directive('bg-color', bgColor)
    app.directive('text-color', textColor)
    app.directive('height', elemHeight)
    app.directive('width', elemWidth)
  },
}
