import { FirebaseOptions, initializeApp } from 'firebase/app'
import Config from '../../../firebaseConfig.json'
import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  TwitterAuthProvider,
} from 'firebase/auth'

// Initialize Firebase

const firebaseApp = initializeApp(Config as FirebaseOptions)
const auth = getAuth(firebaseApp)
const googleAuthProvider = new GoogleAuthProvider()
const twitterAuthProvider = new TwitterAuthProvider()
const facebookAuthProvider = new FacebookAuthProvider()
facebookAuthProvider.addScope('email')
facebookAuthProvider.addScope('public_profile')

export { auth, googleAuthProvider, twitterAuthProvider, facebookAuthProvider }
