<template>
  <div ref="chat" />
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import { getWLChatWidgetCode } from '@/services/whiteLabelService'

const chatWidgetScripts = ref(null)

async function injectScript(script, target) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(script, 'text/html')
  const scriptElements = doc.querySelectorAll('script')

  for (const scriptElement of scriptElements) {
    const newScriptElement = document.createElement('script')
    if (scriptElement.src) {
      await new Promise((resolve, reject) => {
        newScriptElement.src = scriptElement.src
        newScriptElement.onload = resolve
        newScriptElement.onerror = reject
        target.appendChild(newScriptElement)
      })
    } else {
      newScriptElement.innerHTML = scriptElement.innerHTML
      target.appendChild(newScriptElement)
    }
  }
}

onMounted(async () => {
  chatWidgetScripts.value = await getWLChatWidgetCode()
})

watch(chatWidgetScripts, (newValue) => {
  if (newValue) {
    if (newValue.chat_widget_code_in_head) {
      const script = atob(newValue.chat_widget_code_in_head)
      injectScript(script, document.head)
        .then(() => {
          console.log('Chat Support widget loaded successfully in head')
        })
        .catch((error) => {
          console.error('Error in loading Support Chat widget in head', error)
        })
    }
    if (newValue.chat_widget_code_in_body) {
      const script = atob(newValue.chat_widget_code_in_body)
      injectScript(script, document.body)
        .then(() => {
          console.log('Chat Support widget loaded successfully in body')
        })
        .catch((error) => {
          console.error('Error in loading Support Chat widget in body', error)
        })
    }
  }
})
</script>
