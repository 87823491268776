import vuePusher from '../plugins/vuePusher'
import { useNotificationStore } from '@/stores/notifications'
import {
  getSystemAlerts,
  getUserNotification,
} from '@/services/notificationService'
import { useRoute } from 'vue-router'
import { useRootStore } from '@/stores/root'

export const subscribeToPusher = async (userId: string) => {
  try {
    const store = useNotificationStore()
    const route = useRoute()
    const rootStore = useRootStore()

    const channel = vuePusher.subscribe(`notification-channel-${userId}`)
    channel.bind(`notification-event-${userId}`, function (data: never) {
      store.setNewNotification(data)
      getUserNotification()
    })
    channel.bind(
      `access-right-event-${userId}`,
      function (data: {
        id: number
        title: string
        message: string
        type: string
        status: string
        access_action: string
        site_ids: number[]
      }) {
        if (
          (data.access_action === 'updated' ||
            data.access_action === 'removed') &&
          data.status === 'accepted' &&
          (route.name === 'sites' || route.name === 'sites-site_id')
        ) {
          rootStore.showSnackbar(
            data.access_action === 'updated'
              ? 'Site permissions updated. Please refresh your page'
              : 'Your permission for this site have been removed. Please refresh your page',
            'info',
            4000,
          )
        }
      },
    )
    channel.bind(
      `access-sharing-notification-to-owner-${userId}`,
      function (data: never) {
        store.setNewNotification(data)
        getUserNotification()
      },
    )
    const systemAlertChannel = vuePusher.subscribe('bionic-system-alerts')
    systemAlertChannel.bind('bionic-system-alerts', function (data: never) {
      store.setNewSystemAlert(data)
      getSystemAlerts()
    })
  } catch (e) {
    console.error(e)
  }
}

export const unsubscribePusher = async (userId: string) => {
  try {
    vuePusher.unsubscribe(`notification-channel-${userId}`)
    vuePusher.unsubscribe('bionic-system-alerts')
  } catch (e) {
    console.error(e)
  }
}
