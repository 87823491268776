import axiosIns from '@axios'
import { User } from '@/models/user'

export interface CreateUserApiDTO {
  uuid: string
  email: string
  firstName: string
  lastName?: string
  country?: string
  describeAs?: string
  phoneNumber?: string | null
  signupSource?: string | null
  affiliateToken?: string | null
  noOfSites: number
}

export const getUser = async (uid?: string | undefined) => {
  const config = {}
  if (uid) {
    // @ts-ignore
    config.headers = {
      'customer-uuid': uid,
    }
  }
  const { data } = await axiosIns.get('/user', config)
  return data?.data
}

export const createUser = async (user: CreateUserApiDTO) => {
  const { data } = await axiosIns.post('/user/create', {
    ...user,
  })
  return data?.data
}

export const updateUser = async (user: User) => {
  const { data } = await axiosIns.put('/user', {
    uuid: user.uuid,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    country: user.country,
    describeAs: user.describeAs,
    phoneNumber: user.phoneNumber,
  })
  return data?.data
}

export const getSearchedUserByType = (query: string, type: string) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axiosIns.get(
        `admin/users/search/${query}/isAdmin/${type}`,
      )
      resolve(data?.data)
    } catch (e) {
      reject(e)
    }
  })
}

export const getSearchedUserBySites = (query: string) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      query = query.replace('http://', '').replace('https://', '')
      if (query) {
        const { data } = await axiosIns.get(`domain/admin/search?name=${query}`)
        resolve(data?.data)
      } else {
        resolve([])
      }
    } catch (e) {
      reject(e)
    }
  })
}

export const getSearchedUserByEmailOrName = (query: string) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axiosIns.post(
        'admin/users/search/name-or-email/',
        {
          query: query,
        },
      )
      resolve(data?.data)
    } catch (e) {
      reject(e)
    }
  })
}

export const getDashboardResourceStats = () => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axiosIns.get('site/user/dashboard/stats')
      resolve(data?.data)
    } catch (e) {
      reject(e)
    }
  })
}

export const getUserProjectedBillingData = () => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axiosIns.get('site-invoice/upcoming')
      resolve(data?.data)
    } catch (e) {
      reject(e)
    }
  })
}

export const resetPassword = async (email: string) => {
  const { data } = await axiosIns.post('/user/reset-password', {
    email,
  })
  return data?.data
}

export const verifyUserByEmail = async (email: string | null) => {
  const { data } = await axiosIns.post('/user/verify', {
    email,
  })
  return data?.data
}
