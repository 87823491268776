import axiosIns from '@axios'
import { useNotificationStore } from '@/stores/notifications'

export const getUserNotification = async () => {
  const notificationStore = useNotificationStore()
  try {
    const { data } = await axiosIns.get('/user/notification/all')
    notificationStore.setUserNotifications(data?.data)
    if (notificationStore.getUserNotifications.length > 0) {
      return { status: true, message: 'User notification set successfully' }
    }
    return { status: false, message: 'Unable to set user notification' }
  } catch (e) {
    console.log('Unable to get user notification')
  }
}

export const readAllUserNotification = async (id?: number[]) => {
  const notificationStore = useNotificationStore()
  const notificationsIds = notificationStore.getUserNotifications.map((e) => {
    // @ts-ignore
    return e.id
  })
  try {
    const body = {
      id: id ?? notificationsIds,
      status: 'read',
    }
    const { data } = await axiosIns.put('/user/notification/updateStatus', body)
    if (data?.statusCode === 200) {
      await getUserNotification()
      return { status: true, message: 'Notifications Status Updated' }
    }
    return { status: false, message: 'Unable to set user notification' }
  } catch (e) {
    console.log('Unable to get user notification')
  }
}

export const getSystemAlerts = async () => {
  const notificationStore = useNotificationStore()
  try {
    const { data } = await axiosIns.get('/system/alert/withActiveStatus')
    notificationStore.setSystemAlerts(data?.data)
    if (notificationStore.getSystemAlerts.length > 0) {
      return { status: true, message: 'System alerts set successfully' }
    }
    return { status: false, message: 'Unable to set system alerts' }
  } catch (e) {
    console.log('Unable to get system alerts')
  }
}
