import axiosIns from '@axios'
import { useRootStore } from '@/stores/root'

export const checkAgencyNameAvailability = async (key: string) => {
  const { data } = await axiosIns.post(
    '/white-label/validators/is-available/name',
    {
      name: key,
    },
  )
  return data?.data
}
export const checkPortalDomainAvailability = async (key: string) => {
  const { data } = await axiosIns.post(
    '/white-label/validators/is-available/domain',
    {
      domain: key,
    },
  )
  return data?.data
}
export const checkParentDomainAvailability = async (key: string) => {
  const { data } = await axiosIns.post(
    '/white-label/validators/is-available/parent-domain',
    {
      domain: key,
    },
  )
  return data?.data
}
export const checkDevDomainAvailability = async (key: string) => {
  const { data } = await axiosIns.post(
    '/white-label/validators/is-available/dev-domain',
    {
      domain: key,
    },
  )
  return data?.data
}

export const validateStripeSecretKey = async (key: string) => {
  const { data } = await axiosIns.post(
    '/white-label/validators/stripe/api-key/secret',
    {
      stripeApiKey: key,
    },
  )
  return data?.data
}

export const validateStripePublishKey = async (key: string) => {
  const { data } = await axiosIns.post(
    '/white-label/validators/stripe/api-key/publishable',
    {
      stripeApiKey: key,
    },
  )
  return data?.data
}

export const enableWhiteLabel = async (body: FormData) => {
  const { data } = await axiosIns.post('/white-label/enable/', body, {
    timeout: 1000 * 100,
  })
  return data?.data
}

export const customizeWhiteLabelTheme = async (
  white_label_id: number,
  body: FormData,
) => {
  const { data } = await axiosIns.patch(`/white-label/${white_label_id}`, body)
  return data?.data
}

export const getAllCustomers = async (payload: {
  limit: number
  skip: number
  search: string
}) => {
  const url = `/white-label/customers?per_page=${payload.limit ?? 10}&page=${
    payload.skip ?? 0
  }&search=${payload.search}`
  const { data } = await axiosIns.get(url)
  return data?.data
}

export const getWlSites = async (payload: {
  limit: number
  skip: number
  search: string
}) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      payload.search = payload.search
        ?.replace('http://', '')
        .replace('https://', '')
      const url = `/white-label/sites?per_page=${payload.limit ?? 10}&page=${
        payload.skip ?? 0
      }&search=${payload.search}`
      const { data } = await axiosIns.get(url)
      resolve(data?.data)
    } catch (e) {
      reject(e)
    }
  })
}

export const addWlPlan = async (payload: {
  bwp_product_id: string
  type: string
  plan_name: string
  plan_description?: string
  price: number
}) => {
  const { data } = await axiosIns.post('/white-label/payments/plans', payload)
  return data?.data
}

export const getWlPlans = async (payload: {
  limit: number
  skip: number
  planType: string
  planStatus: string
}) => {
  const url = `/white-label/payments/plans?per_page=${
    payload.limit ?? 10
  }&page=${payload.skip ?? 0}&product_type=${payload.planType || ''}&status=${
    payload.planStatus
  }`
  const { data } = await axiosIns.get(url)
  return data?.data
}

export const deactivateWhiteLabel = async (id: number) => {
  const url = `/white-label/${id}`
  const { data } = await axiosIns.delete(url)
  return data?.data
}

export const enableWhiteLabelSupport = async (payload: any) => {
  const { data } = await axiosIns.post(
    '/white-label/payments/enable-wl-support',
    payload,
  )
  return data?.data
}

export const disableWhiteLabelSupport = async (payload: any) => {
  const { data } = await axiosIns.patch(
    '/white-label/payments/disable-wl-support',
    payload,
  )
  return data?.data
}

export const fetchWhiteLabelSiteStats = async () => {
  const { data } = await axiosIns.get('white-label/sites/stats')
  return data?.data
}

export const fetchWhiteLabelDetails = async () => {
  const domainWithoutProtocol = window.location.origin.replace(
    /^https?:\/\//,
    '',
  )
  // const domainWithoutProtocol = 'my.bionicwp.com'
  const { data } = await axiosIns.get(
    `white-label/details/${domainWithoutProtocol}?by=portal_domain`,
  )
  return data
}

export const getWLChatWidgetCode = async () => {
  const url = '/white-label/custom-scripts'
  const { data } = await axiosIns.get(url)
  return data?.data
}
export const getWLChatWidgetCodeById = async (white_label_id: number) => {
  const url = `/white-label/custom-scripts-id/${white_label_id}`
  const { data } = await axiosIns.get(url)
  return data?.data
}

export const updateWhiteLabelChatWidget = async (
  white_label_id: number,
  payload: {
    chat_widget_code_in_head: string
    chat_widget_code_in_body: string
  },
) => {
  const url = `/white-label/custom-scripts/${white_label_id}`
  const { data } = await axiosIns.put(url, payload)
  return data?.data
}

export const getWlOneClickWpAdminLoginUrl = async (siteId: number) => {
  const rootStore = useRootStore()
  rootStore.showSnackbar(
    'Generating one-click login link. This may take a while. Please wait',
    'info',
    5000,
  )
  const { data } = await axiosIns.get(
    `/white-label/sites/${siteId}/one-click-login`,
    {
      timeout: 1000 * 50,
    },
  )
  return data?.data
}

export const getWhiteLabelPostmarkDomainDetails = async () => {
  const { data } = await axiosIns.get('/white-label/support/domain-details')
  return data?.data
}

export const verifyDKIM = async () => {
  const { data } = await axiosIns.patch('/white-label/support/verify/dkim')
  return data?.data
}

export const verifyReturnPath = async () => {
  const { data } = await axiosIns.patch(
    '/white-label/support/verify/return-path',
  )
  return data?.data
}

export const updateSupportEmail = async (payload: {
  support_email: string
}) => {
  const { data } = await axiosIns.put('/white-label/support/email', payload)
  return data?.data
}

export const verifyAndActivateSSLCertAPI = async () => {
  const { data } = await axiosIns.put('/white-label/create-and-activate-ssl')
  return data?.data
}

export const activateSSLCertAPI = async () => {
  const { data } = await axiosIns.put('/white-label/activate-ssl')
  return data?.data
}

export const updateSupportFormToEmail = (email: string) => {
  return axiosIns.put('/white-label/support/support-form/to-email', { email })
}

export const updateSupportCCEmail = (email: string) => {
  return axiosIns.put('/white-label/support/email/cc', { email })
}

export const toggleSupportForm = (status: boolean) => {
  return axiosIns.put(
    `/white-label/support/support-form/${status === true ? 'show' : 'hide'}`,
  )
}

export const updateWhiteLabelPlan = async (
  id: number,
  payload: {
    plan_name: string
    plan_description: string
    price: number
  },
) => {
  const { data } = await axiosIns.patch(
    `/white-label/payments/plans/${id}`,
    payload,
  )
  return data?.data
}

export const archiveWhiteLabelPlan = async (id: number) => {
  const { data } = await axiosIns.delete(
    `/white-label/payments/plans/${id}/archive`,
  )
  return data?.data
}
