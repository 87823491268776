export default {
    VAlert: {
        density: 'comfortable',
        elevation: 0
    },
    VTab: {
        elevation: 0,
        rounded: 0
    },
    VAvatar: {
        // ℹ️ Remove after next release
        variant: 'outlined',
    },
    VBadge: {
        // set v-badge default color to primary
        color: 'primary',
    },
    VBtn: {
        // set v-btn default color to primary
        color: 'primary',
        variant: 'outlined',
        rounded: 'xl',
        elevation: 6,
    },
    VChip: {
        size: 'small',
        rounded: 'xl',
        color: 'main-primary'
    },
    VMenu: {
        VList: {
            density: 'compact',
        },
    },
    VPagination: {
        activeColor: 'error',
        density: 'comfortable',
        variant: 'tonal',
    },
    VTabs: {
        // set v-tabs default color to primary
        color: 'primary',
        density: 'comfortable',
        elevation: 0,
        VTab: {
            elevation: 0,
            rounded: 0,
        }
    },
    VTooltip: {
        // set v-tooltip default location to top
        location: 'top',
    },
    VList: {
        VListItem: {
            activeColor: 'primary',
        },
    },
    VCheckbox: {
        // set v-checkbox default color to primary
        color: 'primary',
        density: 'comfortable',
        hideDetails: 'auto',
    },
    VRadioGroup: {
        color: 'primary',
        density: 'comfortable',
        hideDetails: 'auto',
    },
    VRadio: {
        density: 'comfortable',
        hideDetails: 'auto',
    },
    VSelect: {
        variant: 'outlined',
        density: 'compact',
        color: 'on-primary',
        hideDetails: 'auto',
    },
    VRangeSlider: {
        // set v-range-slider default color to primary
        color: 'primary',
        thumbSize: 14,
        density: 'comfortable',
        thumbLabel: true,
        hideDetails: 'auto',
    },
    VRating: {
        // set v-rating default color to primary
        color: 'warning',
    },
    VProgressCircular: {
        // set v-progress-circular default color to primary
        color: 'primary',
    },
    VSlider: {
        // set v-slider default color to primary
        color: 'primary',
        hideDetails: 'auto',
        thumbSize: 14,
    },
    VTextField: {
        variant: 'outlined',
        density: 'compact',
        color: 'primary',
        hideDetails: 'auto',
        rounded: 'xl',
        bgColor: 'var(--v-textfields-bg)'
    },
    VAutocomplete: {
        variant: 'outlined',
        color: 'primary',
        density: "compact",
        hideDetails: 'auto',
    },
    VCombobox: {
        variant: 'outlined',
        color: 'primary',
        hideDetails: 'auto',
    },
    VFileInput: {
        variant: 'outlined',
        density: 'compact',
        color: 'primary',
        hideDetails: 'auto',
        bgColor: 'var(--v-textfields-bg)',
        rounded: 'xl',
    },
    VTextarea: {
        variant: 'outlined',
        density: 'compact',
        color: 'primary',
        hideDetails: 'auto',
        bgColor: 'var(--v-textfields-bg)'

    },
    VSwitch: {
        // set v-switch default color to primary
        color: 'primary',
        hideDetails: 'auto',
    },
    VTimeline: {
        lineThickness: 1,
    },
    VIcon: {
        size: 24,
        color: 'icons-color'
    },
    VCard: {
        variant: 'outlined'
    },
    VDialog: {
        variant: 'outlined'
    },
}
