<script setup>
import { useUserStore } from '@/stores/user'
import { avatarText } from '@core/utils/formatters'
import { computed } from 'vue'

const userStore = useUserStore()
const userData = computed(() => userStore.userProfile ?? {})
</script>

<template>
  <VCard v-height="'250px'">
    <VRow no-gutters>
      <VCol cols="12" md="12" lg="12">
        <VCardText class="d-flex flex-column">
          <div class="d-flex justify-space-between align-center">
            <VAvatar size="56" variant="tonal">
              <VImg
                v-if="userData.avatar"
                :src="userData.avatarUrl"
                class="image-cover"
              />
              <span v-else>{{
                avatarText(`${userData?.firstName} ${userData?.lastName}`)
              }}</span>
            </VAvatar>
            <span>
              <router-link :to="{ name: 'profile' }" class="text-primary">
                <VBtn variant="outlined" rounded> Visit Profile</VBtn>
              </router-link>
            </span>
          </div>
          <span class="d-flex flex-column mt-5">
            <h6 class="text-lg float-left text-no-wrap font-weight-semibold">
              Hey {{ userData?.firstName?.trim() }}, Welcome Back!
            </h6>
            <p class="mt-1 my-3">
              One of the best ways to influence people is to make those around
              you feel important.
            </p>
          </span>
        </VCardText>
      </VCol>
      <!--      <VCol cols="12">-->
      <!--        <VCardText class="pb-3">-->
      <!--        </VCardText>-->
      <!--      </VCol>-->

      <!--      <VCol cols="4">-->
      <!--        <VCardText class="p-0 px-0 position-relative h-100">-->
      <!--          <VImg-->
      <!--              :src="congoImg"-->
      <!--              height="140"-->
      <!--              class="w-100 position-absolute"-->
      <!--              style="bottom: 0;"-->
      <!--          />-->
      <!--        </VCardText>-->
      <!--      </VCol>-->
    </VRow>
  </VCard>
</template>

<style lang="scss">
.image-cover {
  img {
    object-fit: cover !important;
  }
}
</style>
