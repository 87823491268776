import {breakpointsVuetify} from '@vueuse/core'
import {h} from 'vue'
// @ts-expect-error There won't be declaration file for it
import {VIcon} from 'vuetify/lib/components/VIcon/VIcon'
import {defineThemeConfig} from '@core'
import {RouteTransitions, Skins} from '@core/enums'
import {AppContentLayoutNav, ContentWidth, FooterType, NavbarType,} from '@layouts/enums'
import {getWhiteLabelPortalLogo, getWhiteLabelPortalName, isBionicSite} from '@/utils/whiteLabelHelpers'


export const { themeConfig, layoutConfig } = defineThemeConfig({
  app: {
    title: getWhiteLabelPortalName(),
    logo: h('div', {
      innerHTML: `<img src="${getWhiteLabelPortalLogo()}" height="40px" style="margin-left: 8px" alt="logo" class="img-fluid">`,
      style: 'line-height:0; color: rgb(var(--v-global-theme-primary))',
    }),
    logoMini: h('div', {
      innerHTML: isBionicSite() ?
        '<img src="/lightening-bolt-only-logo.gif" height="40px" alt="logo" class="img-fluid">': null,
      style: 'line-height:0; color: rgb(var(--v-global-theme-primary))' ,
    }),
    contentWidth: ContentWidth.Boxed,
    contentLayoutNav: AppContentLayoutNav.Vertical,
    overlayNavFromBreakpoint: breakpointsVuetify.md + 16,
    enableI18n: false,
    theme: isBionicSite() ? 'bwp-light' : 'light',
    isRtl: false,
    skin: Skins.Default,
    routeTransition: RouteTransitions.Fade,
    iconRenderer: VIcon,
  },
  navbar: {
    type: NavbarType.Sticky,
    navbarBlur: true,
  },
  footer: { type: FooterType.Static },
  verticalNav: {
    isVerticalNavCollapsed: false,
    defaultNavItemIconProps: { icon: 'tabler-circle', size: 10 },
    isVerticalNavSemiDark: false,
  },
  horizontalNav: {
    type: 'sticky',
    transition: 'slide-y-reverse-transition',
  },
  icons: {
    chevronDown: { icon: 'tabler-chevron-down' },
    chevronRight: { icon: 'tabler-chevron-right', size: 18 },
    close: { icon: 'tabler-x' },
    verticalNavPinned: { icon: 'v2-icons:chevron-left' },
    verticalNavUnPinned: { icon: 'v2-icons:chevron-right' },
    sectionTitlePlaceholder: { icon: 'tabler-separator' },
  },
})
