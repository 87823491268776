// make a background color directive
// usage: <div v-bg-color="'red'"></div>

import { Directive } from 'vue'

export const bgColor: Directive = (el: HTMLElement, binding) => {
  if (binding.value) {
    el.style.backgroundColor = binding.value
  }
}

export const textColor: Directive = (el: HTMLElement, binding) => {
  if (binding.value) {
    el.style.color = binding.value
  }
}
