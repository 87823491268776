import { onAuthStateChanged } from 'firebase/auth'
import { auth } from '@/firebase'
import { useUserStore } from '@/stores/user'
import { getUser } from '@/services/userService'
import { User } from '@/models/user'
import { transformToUserProfileModel } from '@/utils/objectTransformers/user'
import { UserAccountType } from '@/common/constants/userContants'
import { cleanUrlAndReturnDomainOnly } from '@/utils/stringHelpers'

export const getCurrentAndParentOrigins = (options?: {
  domainOnly: boolean
}) => {
  const currentOrigin = window.location.origin
  const parentOrigin = window.location.ancestorOrigins?.[0]
  return {
    currentOrigin: options?.domainOnly
      ? cleanUrlAndReturnDomainOnly(currentOrigin)
      : currentOrigin,
    parentOrigin: options?.domainOnly
      ? cleanUrlAndReturnDomainOnly(parentOrigin)
      : parentOrigin,
  }
}
export const isUserLoggedIn = async () => {
  const store = useUserStore()
  const accessToken = await auth.currentUser?.getIdToken()
  if (accessToken) {
    return store.userProfile
  } else {
    return await new Promise((resolve) => {
      const unsub = onAuthStateChanged(auth, async (user) => {
        if (user) {
          store.setFirebaseUser(user)
          getUser()
            .then(async (userProfile: User) => {
              store.setUserProfile(transformToUserProfileModel(userProfile))
              resolve(userProfile)
            })
            .catch(() => {
              resolve(false)
              store.logout()
            })
          if (localStorage.getItem('customer-uuid')) {
            const adminData: User = await getUser(user.uid)
            if (
              adminData?.accountType === UserAccountType.ADMIN ||
              adminData.wlDetails?.id ===
                store.userProfile?.wlPlatformOfCustomer?.id
            ) {
              store.adminProfile = transformToUserProfileModel(adminData)
            } else {
              localStorage.removeItem('customer-uuid')
              store.visitedUID = null
              store.adminProfile = null
            }
          }
        } else {
          store.setFirebaseUser(null)
          resolve(false)
        }
      })
      unsub()
    })
  }
}
