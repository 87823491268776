import moment, { MomentInput } from 'moment'

const DateFilters = {
  MonthDayYear(value: string) {
    return moment.utc(value).format('ll')
  },
  MonthDayYearWithTime(value: string) {
    return moment.utc(value).format('LLL')
  },

  Standard(value: string | Date | MomentInput) {
    return moment(value).format('MM/DD/YYYY')
  },
  RelativeTime(value: string) {
    const dateNow = new Date()
    const date = new Date(value)
    if (dateNow.getDay() === date.getDay()) {
      return moment(value).startOf('seconds').fromNow()
    } else if (dateNow.getDay() - 1 === date.getDay()) {
      return `Yesterday at ${moment(value).format('LT')}`
    } else {
      return this.MonthDayYear(value)
    }
  },
  getDaysFromNowToSpecificDate(value: string) {
    const futureDate = moment(value)
    const diffInDays = futureDate.diff(moment(), 'days')
    if (diffInDays) {
      return `${diffInDays + 1} Days`
    } else {
      const diffInHrs = futureDate.diff(moment(), 'hours')
      if (diffInHrs) {
        return `${diffInHrs + 1} Hours`
      } else {
        const diffInMinutes = futureDate.diff(moment(), 'minutes')
        return `${diffInMinutes} Minutes`
      }
    }
  },
  convertDateIntoDayDateMonthYearHourMinSecUTC(value: string) {
    return moment(value).utc().format('ddd, DD MMM YYYY HH:mm:ss [UTC]')
  },

  formatTimeToAgo(value: string) {
    return moment(value).fromNow()
  },
}

export default DateFilters
